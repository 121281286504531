import Plugin from "../plugin-system/Plugin";
import Splide from '@splidejs/splide';

export default class PictureGalleryPlugin extends Plugin {
    protected splide: Splide | null;
    protected splideThumbnails: Splide | null;
    protected splideEl: HTMLElement | null;
    protected splideSelector: string;
    protected splides: NodeListOf<HTMLElement> | null;
    protected splidesSelector: string;
    protected splideThumbnailsEl: HTMLElement | null;
    protected splideThumbnailsSelector: string;
    protected arrowPath: string;
    protected showArrows: boolean;

    constructor() {
        super('PictureGalleryPlugin');

        this.arrowPath = `M 21.839844 4 L 37.59375 19.507812 L 38 19.90625 L 37.59375 20.304688 L 21.839844 35.816406
            L 20 35.019531 L 33.882812 21.355469 L 2 21.355469 L 2 18.460938 L 33.882812 18.460938 L 20 4.796875 Z
            M 21.839844 4`;
        this.showArrows = true;

        this.splideSelector = '.splide';
        this.splidesSelector = '.splide__slide';
        this.splideThumbnailsSelector = '.picture-gallery__thumbnails';

        this.splideEl = null;
        this.splides = null;
        this.splideThumbnailsEl = null;

        this.splide = null;
        this.splideThumbnails = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.splideEl = this.el.querySelector(this.splideSelector);
        this.splides = this.el.querySelectorAll(this.splidesSelector);
        this.splideThumbnailsEl = this.el.querySelector(this.splideThumbnailsSelector);

        if (this.splides.length <= 1) {
            this.showArrows = false;
        }

        this.initSplides();

        return true;
    }

    initSplides(): void {
        if (!this.splides || !this.splideEl || !this.splideThumbnailsEl) {
            return;
        }

        // Haupt-Slider
        this.splide = new Splide(this.splideEl, {
            arrowPath   : this.arrowPath,
            type       : 'loop',
            pagination : false,
            cover      : false,
            arrows     : this.showArrows
        });

        // Thumbnail-Slider
        this.splideThumbnails = new Splide(this.splideThumbnailsEl, {
            arrowPath   : this.arrowPath,
            rewind      : true,
            fixedWidth  : 250,
            fixedHeight : 110,
            isNavigation: true,
            gap         : 8,
            focus       : 'center',
            pagination  : false,
            cover       : true,
            arrows      : this.showArrows,
            breakpoints : {
                '1200': {
                    fixedWidth  : 160,
                    fixedHeight : 70,
                }
            }
        }).mount();

        this.splide.sync(this.splideThumbnails).mount();
    }
}
