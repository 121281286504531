import Plugin from "../plugin-system/Plugin";
import Animator from "../helper/Animator";

export default class MobileNavigationPlugin extends Plugin {
    protected slideContainerSelector: string;
    protected mobileNavigationItemSelector: string;
    protected mobileNavigationItems: NodeListOf<HTMLElement> | null;
    protected slideContainer: HTMLElement | null;
    protected slideSelector: string;
    protected slideMap: NodeListOf<HTMLElement> | null;
    protected currentSlide: HTMLElement | null;
    protected currentSlideSelector: string;
    protected currentSlideClass: string;
    protected backButton: HTMLElement | null;
    protected backButtonSelector: string;
    protected backButtonActiveClass: string;
    protected slideFadeInClass: string;
    protected slideFadeOutClass: string;
    protected backButtonFadeClass: string;
    protected navigationTrigger: HTMLElement | null;
    protected navigationTriggerSelector: string;
    protected navigationActiveClass: string;
    protected navigationCloseTriggerSelector: string;
    protected navigationCloseTrigger: HTMLElement | null;
    protected navigationWrapperSelector: string;
    protected navigationWrapperSlidingClass: string;
    protected navigationWrapper: HTMLElement | null;
    protected navigationOpen: boolean;
    protected slidingTimer: any;
    protected animationSpeed: number;
    protected navigationActiveBackgroundSelector: string;
    protected navigationActiveBackgroundEl: HTMLElement | null;
    protected navigationActiveBackgroundClass: string;

    constructor() {
        super('MobileNavigationPlugin');

        this.slideContainerSelector = '.mobile-navigation__slide-container';
        this.mobileNavigationItemSelector = '[data-navigation-slide-target]';
        this.slideSelector = '[data-navigation-slide]';
        this.currentSlideSelector = '.js-mobile-navigation__slide--active';
        this.currentSlideClass = 'js-mobile-navigation__slide--active';
        this.slideFadeInClass = 'js-mobile-navigation__slide--fade-in';
        this.slideFadeOutClass = 'js-mobile-navigation__slide--fade-out';
        this.backButtonActiveClass = 'js-mobile-navigation__back-button--active';
        this.backButtonSelector = '.js-mobile-navigation__back-button';
        this.backButtonFadeClass = 'js-mobile-navigation__back-button--fade';
        this.navigationTriggerSelector = '.js-mobile-navigation__trigger';
        this.navigationActiveClass = 'mobile-navigation--collapsed';
        this.navigationCloseTriggerSelector = '.js-mobile-navigation__close-button';
        this.navigationWrapperSelector = '.mobile-navigation__navigation-wrapper';
        this.navigationWrapperSlidingClass = 'mobile-navigation__navigation-wrapper--sliding';
        this.navigationActiveBackgroundSelector = '.mobile-navigation__background';
        this.navigationActiveBackgroundClass = 'mobile-navigation__background--collapsed';

        this.navigationCloseTrigger = null;
        this.navigationTrigger = null;
        this.navigationWrapper = null;
        this.slideContainer = null;
        this.mobileNavigationItems = null;
        this.slideMap = null;
        this.currentSlide = null;
        this.backButton = null;
        this.navigationActiveBackgroundEl = null;

        this.navigationOpen = false;
        this.slidingTimer = -1;
        this.animationSpeed = 330;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        let instance = this;

        super.initPlugin(htmlElement);

        if (this.el === undefined) {
            return false;
        }

        this.slideContainer = this.el.querySelector(this.slideContainerSelector);
        this.mobileNavigationItems = this.el.querySelectorAll(this.mobileNavigationItemSelector);
        this.slideMap = this.el.querySelectorAll(this.slideSelector);
        this.currentSlide = this.el.querySelector(this.currentSlideSelector);
        this.backButton = this.el.querySelector(this.backButtonSelector);
        this.navigationTrigger = document.querySelector(this.navigationTriggerSelector);
        this.navigationCloseTrigger = document.querySelector(this.navigationCloseTriggerSelector);
        this.navigationWrapper = this.el.querySelector(this.navigationWrapperSelector);
        this.navigationActiveBackgroundEl = document.querySelector(this.navigationActiveBackgroundSelector);

        this.mobileNavigationItems.forEach(function (element) {
            element.addEventListener('click', instance.navigationItemClicked.bind(instance));
        });

        if (this.backButton === null) {
            return false;
        }

        if (this.navigationTrigger === null) {
            return false;
        }

        if (this.navigationCloseTrigger === null) {
            return false;
        }

        document.addEventListener('click', instance.documentClicked.bind(instance));

        this.navigationTrigger.addEventListener('click', instance.openNavigation.bind(instance));
        this.navigationCloseTrigger.addEventListener('click', instance.closeNavigation.bind(instance));
        this.backButton.addEventListener('click', instance.backButtonClicked.bind(instance));

        return true;
    }

    protected documentClicked(event: MouseEvent): void {
        if (this.navigationOpen && !this.el.contains(event.target as HTMLElement)) {
            this.closeNavigation();
        }
    }

    protected openNavigation(): void {
        let instance = this;

        if (!this.el.classList.contains(this.navigationActiveClass)) {
            // Muss per Timeout gesetzt werden, da ansonsten das Menü beim öffnen direkt wieder geschlossen wird.
            setTimeout(function () {
                instance.navigationOpen = true;
            }, 100);

            this.el.classList.add(this.navigationActiveClass);
            this.navigationActiveBackgroundEl?.classList.add(this.navigationActiveBackgroundClass);
        } else {
            this.el.classList.remove(this.navigationActiveClass);
            this.navigationActiveBackgroundEl?.classList.remove(this.navigationActiveBackgroundClass);
        }
    }

    protected closeNavigation(): void {
        this.el.classList.remove(this.navigationActiveClass);
        this.navigationActiveBackgroundEl?.classList.remove(this.navigationActiveBackgroundClass);

        this.navigationOpen = false;
    }

    protected backButtonClicked(): void {
        let parentSlideId: string | undefined;
        let targetSlideElement: HTMLElement | undefined;

        if (this.currentSlide === null) {
            throw new Error('Current slide not defined. Cannot detect parent');
        }

        if (this.slideMap === null) {
            throw new Error('There are no slides to target in mobile navigation');
        }

        parentSlideId = this.currentSlide.dataset.parentNavigationSlide;

        this.slideMap.forEach(function(slideElement) {
            if (slideElement.dataset.navigationSlide === parentSlideId) {
                targetSlideElement = slideElement;
            }
        });

        if (targetSlideElement === undefined) {
            throw new Error('Slide target not found: "' + parentSlideId + '"');
        }

        this.changeSlides(targetSlideElement, true);
    }

    protected navigationItemClicked(event: MouseEvent): void {
        if (this.slideMap === null) {
            throw new Error('There are no slides to target in mobile navigation');
        }

        let clickedElement = event.currentTarget as HTMLElement;
        let dataSlideTarget = clickedElement.dataset.navigationSlideTarget;
        let targetSlideElement: HTMLElement | undefined;

        this.slideMap.forEach(function(slideElement) {
            if (slideElement.dataset.navigationSlide === dataSlideTarget) {
                targetSlideElement = slideElement;
            }
        });

        if (targetSlideElement === undefined) {
            throw new Error('Slide target not found: "' + dataSlideTarget + '"');
        } else {
            event.preventDefault();
        }

        this.changeSlides(targetSlideElement);
    }

    protected changeSlides(newSlide: HTMLElement, reverse = false): void {
        if (this.currentSlide === null) {
            throw new Error('Cannot change slides. The currently active slide is missing');
        }

        let instance = this;
        let currentSlide = this.currentSlide;

        let fadeOutAnimator = new Animator(currentSlide);
        let fadeInAnimator = new Animator(newSlide);

        if (!reverse) {
            fadeInAnimator.addStep({
                callback: function (animator) {
                    newSlide.classList.add(instance.currentSlideClass);
                    newSlide.classList.add(instance.slideFadeInClass);
                    animator.triggerReflow();

                    return animator.nextStep();
                }
            }).addStep({
                removeClass: this.slideFadeInClass
            }).run();

            fadeOutAnimator.addStep({
                addClass: this.slideFadeOutClass,
                removeClass: this.currentSlideClass
            }).addStep({
                removeClass: this.slideFadeOutClass
            }).run();
        } else {
            fadeInAnimator.addStep({
                callback: function (animator) {
                    newSlide.classList.add(instance.currentSlideClass);
                    newSlide.classList.add(instance.slideFadeOutClass);
                    animator.triggerReflow();

                    return animator.nextStep();
                }
            }).addStep({
                removeClass: this.slideFadeOutClass
            }).run();

            fadeOutAnimator.addStep({
                addClass: this.slideFadeInClass,
                removeClass: this.currentSlideClass
            }).addStep({
                removeClass: this.slideFadeInClass
            }).run();
        }

        this.currentSlide = newSlide;

        if (this.backButton !== null) {
            let buttonAnimator = new Animator(this.backButton);
            let backButton = this.backButton;

            if (this.currentSlide.dataset.parentNavigationSlide !== undefined) {
                buttonAnimator.addStep({
                    callback: function(animator) {
                        backButton.classList.add(instance.backButtonActiveClass);
                        animator.triggerReflow();

                        return animator.nextStep();
                    }
                }).addStep({
                    addClass: this.backButtonFadeClass
                }).run();
            } else {
                buttonAnimator.addStep({
                    removeClass: this.backButtonFadeClass
                }).addStep({
                    removeClass: this.backButtonActiveClass
                }).run();
            }
        }

        clearTimeout(this.slidingTimer);

        this.navigationWrapper?.classList.add(this.navigationWrapperSlidingClass);

        this.slidingTimer = setTimeout(() => {
            this.navigationWrapper?.classList.remove(this.navigationWrapperSlidingClass);
        }, this.animationSpeed);
    }
}
