import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import PubSub from 'pubsub-js';

export default class ExpanderPlugin extends Plugin {
    constructor() {
        super('ExpanderPlugin');

        this.config.expanderTargetSelector = '.expander__target';
        this.config.expanderButtonSelector = '.expander__button';
        this.config.expanderSelector = '.expander';
        this.config.expanderClosedCls = 'expander--closed';
        this.config.expanderOpenCls = 'expander--open';
        this.config.expanderHiddenCls = 'expander--hidden';
        this.config.expanderTargetClosedCls = 'expander__target--closed';
        this.config.expanderTargetOpenCls = 'expander__target--open';
        this.config.expanderTargetHiddenCls = 'expander__target--hidden';
        this.config.expanderHolderActiveCls = 'expander__holder--active';
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        let expanderTargetEl = this.el.querySelector(this.config.expanderTargetSelector);
        let expanderBtnEl = this.el.querySelector(this.config.expanderButtonSelector);
        let expanderEl = this.el.querySelector(this.config.expanderSelector);

        if (expanderTargetEl && expanderBtnEl && expanderEl) {
            this.store.expanderTargetEl = expanderTargetEl;
            this.store.expanderBtnEl = expanderBtnEl;
            this.store.expanderEl = expanderEl;
            this.store.xsViewport = window.matchMedia('(max-width: 787px)');

            setTimeout(this.onResize.bind(this), 500);

            this.registerEvents();
        }

        return true;
    }

    registerEvents(): void {
        let btnEl = this.store.expanderBtnEl as HTMLElement,
            expanderTargetEl = this.store.expanderTargetEl as HTMLElement;

        expanderTargetEl.addEventListener('transitionend', this.stateAnimationFinished.bind(this))
        btnEl.addEventListener('click', this.onButtonClick.bind(this));
        window.addEventListener('resize', this.onResize.bind(this));
    }

    stateAnimationFinished(): void {
        PubSub.publish('ExpanderPlugin/stateAnimationFinished');
    }

    onResize(): void {
        let expanderTargetEl = this.store.expanderTargetEl as HTMLElement,
            scrollHeight = expanderTargetEl.scrollHeight,
            showExpander = scrollHeight > 200;

        if (showExpander) {
            this.showExpander();
        } else {
            this.hideExpander();
        }

        if (this.store.xsViewport.matches && showExpander) {
            expanderTargetEl.style.height = expanderTargetEl.scrollHeight + 'px';
        } else {
            expanderTargetEl.style.height = '';
        }
    }

    showExpander(): void {
        let expanderTargetEl = this.store.expanderTargetEl;
        let expanderEl = this.store.expanderEl;

        expanderTargetEl.classList.remove(this.config.expanderTargetHiddenCls);
        expanderEl.classList.remove(this.config.expanderHiddenCls);
        this.el.classList.add(this.config.expanderHolderActiveCls);

        PubSub.publish('ExpanderPlugin/showExpander');
    }

    hideExpander(): void {
        let expanderTargetEl = this.store.expanderTargetEl;
        let expanderEl = this.store.expanderEl;

        expanderTargetEl.style.height = '';
        expanderTargetEl.classList.add(this.config.expanderTargetHiddenCls);
        expanderEl.classList.add(this.config.expanderHiddenCls);
        this.el.classList.remove(this.config.expanderHolderActiveCls);

        PubSub.publish('ExpanderPlugin/hideExpander');

        this.openExpander();
    }

    onButtonClick(): void {
        this.toggleExpanderState();
    }

    toggleExpanderState(): void {
        let expanderState = 'open',
            expanderEL = this.store.expanderEl as HTMLElement;

        if (expanderEL.classList.contains(this.config.expanderClosedCls)) {
            expanderState = 'closed';
        }

        if (expanderState === 'open') {
            this.closeExpander();
        } else {
            this.onResize();
            this.openExpander();
        }
    }

    closeExpander(): void {
        let expanderEL = this.store.expanderEl as HTMLElement,
            expanderTargetEL = this.store.expanderTargetEl as HTMLElement;

        expanderEL.classList.remove(this.config.expanderOpenCls);
        expanderEL.classList.add(this.config.expanderClosedCls);

        expanderTargetEL.classList.remove(this.config.expanderTargetOpenCls);
        expanderTargetEL.classList.add(this.config.expanderTargetClosedCls);
    }

    openExpander(): void {
        let expanderEL = this.store.expanderEl as HTMLElement,
            expanderTargetEL = this.store.expanderTargetEl as HTMLElement;

        expanderEL.classList.add(this.config.expanderOpenCls);
        expanderEL.classList.remove(this.config.expanderClosedCls);

        expanderTargetEL.classList.add(this.config.expanderTargetOpenCls);
        expanderTargetEL.classList.remove(this.config.expanderTargetClosedCls);
    }
}
