import Plugin from "../plugin-system/Plugin";
// @ts-ignore
import PubSub from 'pubsub-js';

export default class BrickManagerPlugin extends Plugin {
    protected brickList: HTMLCollection | null;
    protected marginalColumn: HTMLElement | null;

    constructor() {
        super('BrickManagerPlugin');

        this.config = {
            marginalColumnSelector: '.marginal-column',
            marginalColumnExpanderOverlayClass: 'marginal-column--expander-overlay',
            expanderClass: 'expander__holder',
            expanderPaddingTopClass: 'expander__holder--padding-top',
            expanderPaddingBottomClass: 'expander__holder--padding-bottom',
            expanderLabelSelector: '.expander__label',
            expanderLabelLightClass: 'expander__label--light',
            expanderLabelDarkClass: 'expander__label--dark',
            lightThemes: [
                'theme__secondary',
                'theme__tertiary'
            ]
        }

        this.store.xsViewport = window.matchMedia('(max-width: 787px)');

        this.brickList = null;
        this.marginalColumn = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.brickList = this.el.children;
        this.marginalColumn = document.querySelector(this.config.marginalColumnSelector);

        if (
            this.marginalColumn
            && this.brickList.length
            && this.brickList[this.brickList.length - 1].classList.contains(this.config.expanderClass)
        ) {
            this.marginalColumn.classList.add(this.config.marginalColumnExpanderOverlayClass);
        }

        this.setZIndex();
        this.updateClasses();
        this.evaluateAdjustmentClasses();
        this.registerEvents();

        return true;
    }

    protected registerEvents(): void {
        PubSub.subscribe('ExpanderPlugin/showExpander', this.evaluateAdjustmentClasses.bind(this));
        PubSub.subscribe('ExpanderPlugin/hideExpander', this.evaluateAdjustmentClasses.bind(this));

        window.addEventListener('resize', this.updateClasses.bind(this));
    }

    protected setZIndex(): void {
        if (this.brickList === null) {
            return
        }

        for (let brickId = this.brickList.length - 1; brickId >= 0; brickId--) {
            let brick = this.brickList[brickId] as HTMLElement;

            brick.style.position = 'relative';
            brick.style.zIndex = (this.brickList.length - brickId).toString();
        }
    }

    protected updateClasses(): void {
        if (this.brickList === null) {
            return;
        }

        let lastBrick = null;

        for (let brickId = 0; brickId < this.brickList.length; brickId++) {
            let brick = this.brickList[brickId] as HTMLElement;

            if (brick !== null) {
                this.updateExpanderClass(brick, lastBrick);
            }

            lastBrick = brick;
        }
    }

    protected evaluateAdjustmentClasses(): void {
        if (this.brickList === null) {
            return;
        }

        for (let brickId = 0; brickId < this.brickList.length; brickId++) {
            let brick = this.brickList[brickId] as HTMLElement;

            brick.style.position = 'relative';
            brick.style.zIndex = (this.brickList.length - brickId).toString();
        }
    }

    protected updateExpanderClass(brick: HTMLElement, lastBrick: HTMLElement | null): void {
        brick.classList.remove(this.config.expanderPaddingTopClass);
        brick.classList.remove(this.config.expanderPaddingBottomClass);

        if (lastBrick !== null) {
            if (lastBrick.classList.contains(this.config.expanderClass)) {
                // Wenn der aktuelle Brick ein Light Theme ist,
                // muss der "mehr Lesen" Button des vorherigen Expanders
                // eine dunkle Farbe erhalten
                if (this.config.lightThemes.some(
                    (theme: any) => brick.classList.contains(theme)
                )) {
                    lastBrick.querySelector(this.config.expanderLabelSelector)
                        .classList.add(this.config.expanderLabelDarkClass);
                } else {
                    lastBrick.querySelector(this.config.expanderLabelSelector)
                        .classList.add(this.config.expanderLabelLightClass);
                }
            }
        }

        // Wenn 2 Expander Klassen aufeinander folgen
        if (
            brick.classList.contains(this.config.expanderClass)
            && lastBrick !== null
            && lastBrick.classList.contains(this.config.expanderClass)
            && this.store.xsViewport.matches
        ) {
            brick.classList.add(this.config.expanderPaddingTopClass);
            brick.classList.add(this.config.expanderPaddingBottomClass);
        }
    }
}
