import Plugin from "../plugin-system/Plugin";

export interface Viewports {
    [index: string]: number;
}

export default class MarginalColumnPlugin extends Plugin {
    protected viewports: Viewports;
    protected lastNavListTop: number;
    protected currentViewport: number;
    protected marginalColumnSelector: string;
    protected headerSelector: string;
    protected marginalColumnAreaSelector: string;
    protected marginalColumnsHolderSelector: string;
    protected header: HTMLElement | null;
    protected marginalColumnEl: HTMLElement | null;
    protected marginalColumnsHolderEl: HTMLElement | null;
    protected marginalColumnAreaElements: NodeListOf<HTMLElement> | null;

    constructor() {
        super('MarginalColumnPlugin');

        this.headerSelector = '.header';
        this.marginalColumnSelector = '.marginal-column';
        this.marginalColumnAreaSelector = '.global--marginal-column';
        this.marginalColumnsHolderSelector = '.marginal-columns';

        this.header = null;
        this.marginalColumnEl = null;
        this.marginalColumnsHolderEl = null;
        this.marginalColumnAreaElements = null;

        this.lastNavListTop = 0;

        this.viewports = {
            sm: 767,
            md: 1199,
            xl: 9999
        };

        this.currentViewport = this.viewports.sm;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        let bodyEl = document.querySelector('body'),
            aosDuration = 0,
            aosDelay = 0;

        if (bodyEl) {
            aosDuration = bodyEl.dataset.aosDuration
                ? parseInt(bodyEl.dataset.aosDuration) : 0;
            aosDelay = bodyEl.dataset.aosDelay
                ? parseInt(bodyEl.dataset.aosDelay) : 0;
        }

        this.currentViewport = this.detectViewport();

        this.header = document.querySelector(this.headerSelector);
        this.marginalColumnsHolderEl = document.querySelector(this.marginalColumnsHolderSelector);
        this.marginalColumnAreaElements = document.querySelectorAll(this.marginalColumnAreaSelector);

        if (this.marginalColumnsHolderEl) {
            this.marginalColumnEl = this.marginalColumnsHolderEl.querySelector(this.marginalColumnSelector);
        }

        this.repositionMarginalColumns();

        // Neu positionieren, wenn AOS fertig ist
        setTimeout(this.repositionMarginalColumns.bind(this), aosDuration + aosDelay + 100);

        this.registerEvents();

        return true;
    }

    registerEvents(): void {
        window.addEventListener('resize', this.repositionMarginalColumns.bind(this));
        window.addEventListener('scroll', this.repositionMarginalColumns.bind(this));
    }

    repositionMarginalColumns(): void {
        let areaTop = 0,
            areaPaddingTop = 0,
            areaPaddingBottom = 0,
            areaHeight = 0,
            columnsHeight = 0,
            navHeight = 0,
            minDistanceTop = 0;

        if (
            !this.marginalColumnsHolderEl
            || !this.marginalColumnEl
            || !this.marginalColumnAreaElements
            || !this.header
        ) {
            return;
        }

        this.currentViewport = this.detectViewport();

        if (this.currentViewport === this.viewports.sm) {
            this.marginalColumnsHolderEl.style.top = "";
        } else {
            navHeight = this.header.offsetHeight;

            areaTop = this.detectElementPosition(this.marginalColumnAreaElements[0]);
            areaHeight = this.detectElementsHeight(this.marginalColumnAreaElements);
            columnsHeight = this.marginalColumnsHolderEl.offsetHeight;
            minDistanceTop = navHeight + 30;
            areaPaddingTop = parseInt(
                window.getComputedStyle(
                    this.marginalColumnAreaElements[0],
                    null
                ).getPropertyValue('padding-top')
            );
            areaPaddingBottom = parseInt(
                window.getComputedStyle(
                    this.marginalColumnAreaElements[this.marginalColumnAreaElements.length - 1],
                    null
                ).getPropertyValue('padding-bottom')
            );

            if ((areaTop + areaPaddingTop) < minDistanceTop) {
                if ((columnsHeight + minDistanceTop + areaPaddingBottom) > areaTop + areaHeight) {
                    this.marginalColumnsHolderEl.style.top = (areaTop + areaHeight - columnsHeight - areaPaddingBottom) + 'px';
                } else {
                    this.marginalColumnsHolderEl.style.top = minDistanceTop + 'px';
                }

                this.marginalColumnEl.style.marginTop = '0px';
            } else {
                this.marginalColumnsHolderEl.style.top = areaTop + 'px';
                this.marginalColumnEl.style.marginTop = '';
            }
        }
    }

    detectElementPosition(el: HTMLElement | null): number
    {
        let targetElPos;
        let targetElPosY = 0;

        if (el !== null) {
            targetElPos = el.getBoundingClientRect();
            targetElPosY = targetElPos.y || targetElPos.top;
        }

        return targetElPosY;
    }

    detectElementsHeight(els: NodeListOf<HTMLElement> | null): number
    {
        let height = 0;

        els?.forEach(el => height += el.offsetHeight);

        return height;
    }

    checkNavigationPosition(): void
    {
        if (
            this.lastNavListTop !== this.detectElementPosition(this.header)
            && this.currentViewport === this.viewports.xl
        ) {
            this.repositionMarginalColumns();
        }
    }

    detectViewport(): number {
        if (window.innerWidth <= this.viewports.sm) {
            return this.viewports.sm;
        } else if(window.innerWidth <= this.viewports.md) {
            return this.viewports.md;
        } else {
            return this.viewports.xl;
        }
    }
}
