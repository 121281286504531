import 'bootstrap';

import PluginManager from "./plugin-system/PluginManager";
import isIE11 from "./utilities/ie-detect";

import '../scss/app.scss';

import ScrollMenuPlugin from "./plugins/ScrollMenuPlugin";
import MobileNavigationPlugin from "./plugins/MobileNavigationPlugin";
import AnchorScrollPlugin from "./plugins/AnchorScrollPlugin";
import ExpanderPlugin from "./plugins/ExpanderPlugin";
import ServiceBadgePlugin from "./plugins/ServiceBadgePlugin";
import ScrollAnimationPlugin from "./plugins/ScrollAnimationPlugin";
import MarginalColumnPlugin from "./plugins/MarginalColumnPlugin";
import PictureGalleryPlugin from "./plugins/PictureGalleryPlugin";

// @ts-ignore
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/print/ls.print';
import NestedMenuPlugin from "./plugins/NestedMenuPlugin";
import BrickManagerPlugin from "./plugins/BrickManagerPlugin";
import ScrollTopPlugin from "./plugins/ScrollTopPlugin";

lazysizes.init();

if (isIE11) {
    // @ts-ignore
    import('picturefill');
}

document.addEventListener('DOMContentLoaded', function() {
    if (isIE11) {
        document.body.classList.add('is-ie');
    }
});

const pluginManager = new PluginManager();

pluginManager.registerPlugin(ScrollMenuPlugin, 'body');
pluginManager.registerPlugin(MobileNavigationPlugin, '.mobile-navigation');
pluginManager.registerPlugin(AnchorScrollPlugin, 'body');
pluginManager.registerPlugin(ExpanderPlugin, '[data-expander]');
pluginManager.registerPlugin(ScrollTopPlugin, '[data-scroll-top]');
pluginManager.registerPlugin(ServiceBadgePlugin, '.service-badge');
pluginManager.registerPlugin(NestedMenuPlugin, '.mega-menu__container');
pluginManager.registerPlugin(ScrollAnimationPlugin, 'body');
pluginManager.registerPlugin(BrickManagerPlugin, '.content-main');
pluginManager.registerPlugin(MarginalColumnPlugin, '.marginal-columns');
pluginManager.registerPlugin(PictureGalleryPlugin, '.picture-gallery');
